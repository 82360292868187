import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        document.getElementById("user_password").addEventListener("input", () => {
            this.validatePassword();
        });

        // At first we call the validatePassword function once
        this.validatePassword();
    }

    validatePassword() {
        const passwordInput = document.getElementById("user_password");
        const minLength = document.getElementById("minLength");
        const uppercase = document.getElementById("uppercase");
        const lowercase = document.getElementById("lowercase");
        const number = document.getElementById("number");
        const symbol = document.getElementById("symbol");

        const password = passwordInput.value;
        minLength.classList.toggle('fulfilled', password.length >= 8);
        uppercase.classList.toggle('fulfilled', /[A-Z]/.test(password));
        lowercase.classList.toggle('fulfilled', /[a-z]/.test(password));
        number.classList.toggle('fulfilled', /\d/.test(password));
        symbol.classList.toggle('fulfilled', /[^\w\s]/.test(password));
    }
}
