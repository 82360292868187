// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

global.toastr = require("toastr")
global.toastr.options = {
    "positionClass": "toast-bottom-right",
    "timeOut": "10000"
}

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"

require("jquery")
require("@popperjs/core")

//require('moment/moment')
//require("moment/locale/tr")

import 'bootstrap'
import { Modal } from 'bootstrap'
import { Tooltip, Popover } from 'bootstrap'
require("fontawesome-free")
//window.moment = moment;

//require("@eonasdan/tempus-dominus")
//import { TempusDominus } from '@eonasdan/tempus-dominus';

//require("daterangepicker")

require('@kollegorna/cocoon-vanilla-js')

require('select2/dist/js/select2.full')
require('select2/dist/js/i18n/tr')

require('inputmask/dist/inputmask');

// bootstrap icons
import 'bootstrap-icons/font/bootstrap-icons.css'

import './styles.scss';

window.jQuery = $;
window.$ = $;
//window.moment = moment;


// require('summernote/dist/summernote')
// require('summernote/dist/summernote-bs5');
// require('summernote/dist/lang/summernote-tr-TR');

$( document ).on('turbolinks:load', function() {
    //$('.date_time_picker').datetimepicker({locale: 'tr'});
    //$('.date_picker').datetimepicker({locale: 'tr', format: 'L'});

    $('.city_select').on('change', function (e){
        let city_id = this.value;
        if (city_id != null){
            $.ajax({
                type: 'GET',
                url: `/counties/${city_id}`,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (data) {
                    let county = $('.county_select');
                    county.empty();
                    county.append('<option value>Lütfen seçiniz</option>')
                    for (let i = 0; i < data.length; i++) {
                        county.append(`<option value=${data[i][0]}>${data[i][1]}</option>`)
                    }
                }
            });
        }
    });


    $('[data-bs-toggle="tooltip"]').tooltip()
    $('[data-bs-toggle="popover"]').popover()

    $('.select2').select2({
        theme: 'bootstrap-5',
    });

    // $('.summernote').summernote({
    //     lang: 'tr-TR',
    //     height: 120,
    //     prettifyHtml: true,
    //     codeviewFilter: true,
    //     codeviewFilterRegex: /onerror=|<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml)[^>]*?>/gi,
    //     codeviewIframeFilter: true,
    //     codeviewIframeWhitelistSrc: ['darphane.org.tr', 'localhost'],
    //     toolbar: [
    //         ['style', ['style']],
    //         ['font', ['bold', 'underline', 'clear']],
    //         ['fontname', ['fontname']],
    //         ['color', ['color']],
    //         ['para', ['ul', 'ol', 'paragraph']],
    //         ['table', ['table']],
    //         ['insert', ['link']],
    //         ['view', ['fullscreen', 'help']],
    //     ]
    // })
    //
    // $('.summernote_medium').summernote({
    //     lang: 'tr-TR',
    //     height: 200,
    //     prettifyHtml: true,
    //     codeviewFilter: true,
    //     codeviewFilterRegex: /onerror=|<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml)[^>]*?>/gi,
    //     codeviewIframeFilter: true,
    //     codeviewIframeWhitelistSrc: ['darphane.org.tr', 'localhost'],
    //     toolbar: [
    //         ['style', ['style']],
    //         ['font', ['bold', 'underline', 'clear']],
    //         ['fontname', ['fontname']],
    //         ['color', ['color']],
    //         ['para', ['ul', 'ol', 'paragraph']],
    //         ['table', ['table']],
    //         ['insert', ['link']],
    //         ['view', ['fullscreen', 'help']],
    //     ]
    // })
    //
    // $('.summernote_plain').summernote({
    //     lang: 'tr-TR',
    //     height: 120,
    //     prettifyHtml: true,
    //     codeviewFilter: true,
    //     codeviewFilterRegex: /onerror=|<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml)[^>]*?>/gi,
    //     codeviewIframeFilter: true,
    //     codeviewIframeWhitelistSrc: ['darphane.org.tr', 'localhost'],
    //     toolbar: []
    // })

    var phoneInput = document.getElementById('user_phone_attributes_number');
    if (phoneInput) {
        Inputmask({"mask": "0599-999-99-99"}).mask(phoneInput);
    }

    var settingPricePerProduct = document.getElementById('setting_price_per_product');
    if (settingPricePerProduct) {
        Inputmask({
            alias: 'numeric',
            min: 1,
            allowMinus: false,
            digits: 2, // Number of decimal places
            digitsOptional: false, // Ensures decimal part is mandatory
            radixPoint: ".", // Decimal separator
            groupSeparator: ",", // Thousand separator
            autoGroup: true, // Automatically adds thousand separators
            rightAlign: false
        }).mask(settingPricePerProduct);
    }

    var monthlyCapacityInput = document.getElementById('product_monthly_capacity');
    if (monthlyCapacityInput) {
        Inputmask({
            alias: 'numeric',
            min: 1,
            allowMinus: false,
            digits: 0,
            rightAlign: false
        }).mask(monthlyCapacityInput);
    }

});



